/* eslint-disable react/no-unescaped-entities */
import ReactTooltip from 'react-tooltip'
import { Formik, FormikHelpers, FormikProps, FormikValues } from 'formik'
import { useRouter } from 'next/router'
import React, { useEffect, useRef, useState } from 'react'
import Layout from 'components/common/layout/Layout'
import { Input } from 'components/common/field/input'
import { useAuthContext } from 'hooks/AuthContext'
import { Card } from 'components/common/card'
import { Label } from 'components/common/label'
import { NiceButton } from 'components/common/button'
import { BASE_URL, NEXT_PUBLIC_GOOGLE_AUTH_ENABLE } from 'env'
import Logo from 'components/common/logo/Logo'
import GoogleAuth from '../../components/login-page/GoogleAuth'
import LoginForm from '../../components/login-page/LoginForm'

export default function Index() {
  const { push } = useRouter()
  const { actions: contextActions, state } = useAuthContext()
  const router = useRouter()
  const [loading, setLoading] = useState(false)
  const [knownUser, setKnownUser] = useState(false)
  const [passAuth, setPassAuth] = useState(false)
  const [unsettingToken, setUnsettingToken] = useState(false)
  const [newUser, setNewUser] = useState(false)
  const [referralId, setReferralId] = useState<string | null>(null)
  const [showTooltip, setShowTooltip] = useState(false)

  const formRef = useRef<FormikProps<FormikValues>>(null)
  const [email, setEmail] = useState('')
  const [globalError, setGlobalError] = useState('')

  useEffect(() => {
    setEmail(new URLSearchParams(window.location.search).get('email') as string)
    setReferralId(
      new URLSearchParams(window.location.search).get('refferal-id'),
    )
    setShowTooltip(true)
  }, [])

  useEffect(() => {
    if (formRef.current && email) {
      formRef.current.handleSubmit()
    }
  }, [email])
  if (state.key) {
    if (newUser) {
      router.push('/login/role')
      // goToRoom()
    } else if (state.userType === 'teacher') {
      push('/user/permanent')
    } else if (state.userType === 'student') {
      push('/student/teacher')
    } else if (state.userType === 'none') {
      router.push('/login/role')
    }
  }
  const handleSubmit = async (values: FormikValues, actions: FormikHelpers<FormikValues>) => {
    setLoading(true)
    setGlobalError('')
    const searchParams = new URLSearchParams(window.location.search)
    const utmParams = {}

    Array.from(searchParams.entries()).forEach(([key, value]) => {
      if (key.startsWith('utm_')) {
        utmParams[key] = value
      }
    })
    const emailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)
    if (!emailValid) {
      actions.setFieldError(
        'email',
        'Please enter a valid email address.',
      )
      setLoading(false)
      return
    }

    if (!knownUser) {
      const tempNewUser = {
        email: values?.email,
        utm_queries: utmParams || null,
        referral_id: referralId || null,
      }
      await fetch(`${BASE_URL}/api/v1/auth/email/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(tempNewUser),
      }).then(async (res) => {
        // console.log(res)
        if (res.ok) {
          const body = await res.json()
          const key = body?.token
          if (key) {
            contextActions.setKey(key)
            setNewUser(true)
            // goToRoom()
            router.push('/login/role')
          } else {
            setKnownUser(true)
            const authTypePass = body?.auth_type === 'password'
            setPassAuth(authTypePass)
          }
        }
      })
    } else if (passAuth) {
      const tempNewUser = {
        email: values?.email,
        password: values?.password,
      }
      actions.setFieldTouched('password', true)
      await fetch(`${BASE_URL}/api/v1/auth/login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(tempNewUser),
      }).then(async (res) => {
        const body = await res.json()
        if (res.ok) {
          const key = body?.key
          if (key) {
            contextActions.setKey(key)
            router.push('/user')
          }
        } else {
          Object.keys(body).forEach((key) => {
            const value = body[key]
            if (Array.isArray(value)) {
              actions.setFieldError(key, value[0])
            } else {
              actions.setFieldError(key, value)
            }
          })
        }
      })
    } else if (unsettingToken) {
      const tempNewUser = {
        email: values?.email,
        token: values?.token,
      }
      await fetch(`${BASE_URL}/api/v1/auth/unset/token/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(tempNewUser),
      }).then(async (res) => {
        const body = await res.json()
        if (res.ok) {
          const key = body?.key
          if (key) {
            contextActions.setKey(key)
            router.push('/user')
          }
        } else {
          Object.keys(body).forEach((key) => {
            const value = body[key]
            if (Array.isArray(value)) {
              actions.setFieldError(key, value[0])
            } else {
              actions.setFieldError(key, value)
            }
          })
        }
      })
    } else {
      const tempNewUser = {
        email: values?.email,
        token: values?.token,
        referral_id: referralId || null,
      }
      await fetch(`${BASE_URL}/api/v1/auth/token/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(tempNewUser),
      }).then(async (res) => {
        const body = await res.json()
        if (res.ok) {
          const key = body?.token
          if (key) {
            contextActions.setKey(key)
            router.push('/user')
          }
        } else {
          Object.keys(body).forEach((key) => {
            const value = body[key]
            if (Array.isArray(value)) {
              actions.setFieldError(key, value[0])
            } else {
              actions.setFieldError(key, value)
            }
          })
        }
      })
    }

    setLoading(false)
  }

  useEffect(() => {
    if (passAuth) {
      const passwordInput = document.getElementById('password-input')
      if (passwordInput) {
        passwordInput.focus()
      }
    }
  }, [passAuth])

  return (
    <Layout meta={{ robots: 'index, archive, follow' }} showNavbar={false}>
      <div className="flex flex-col justify-center items-center py-6">
        <Logo />
      </div>
      <Formik
        innerRef={formRef}
        initialValues={
                    { email } as {
                        non_field_errors?: null
                        email?: string
                    }
        }
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {(props) => {
          const tempGlobalError = globalError || props?.errors?.non_field_errors?.toString()
          return (
            <form
              className="self-center flex justify-center w-full flex-col items-center"
              onSubmit={props.handleSubmit}
            >
              <Card>
                {NEXT_PUBLIC_GOOGLE_AUTH_ENABLE
                  ? (
                    <div className="flex flex-col relative mb-5">
                      <Card.Title>Login or Sign Up</Card.Title>
                      <GoogleAuth />
                    </div>
                  )
                  : <Card.Title>Login or Sign Up</Card.Title>}
                <Card.Content>
                  <Label.Box>
                    <Label>email</Label>
                    <Input
                      name="email"
                      placeholder="Enter your Email"
                      type="text"
                    />
                  </Label.Box>
                  <LoginForm
                    props={props}
                    passAuth={passAuth}
                    setLoading={setLoading}
                    setPassAuth={setPassAuth}
                    setGlobalError={setGlobalError}
                    setKnownUser={setKnownUser}
                    setUnsettingToken={setUnsettingToken}
                    knownUser={knownUser}
                    tempGlobalError={tempGlobalError}
                  />
                </Card.Content>
                <Card.Actions>
                  <Card.Actions.Main>
                    <NiceButton
                      loading={loading}
                      fullwidth
                      variant="primary"
                      size="big"
                      type="submit"
                    >
                      <p className="text-2xl">{unsettingToken ? 'Unset password' : 'Login'}</p>
                    </NiceButton>
                  </Card.Actions.Main>
                </Card.Actions>
              </Card>
            </form>
          )
        }}
      </Formik>
      {showTooltip && (
        <ReactTooltip
          place="top"
          type="dark"
          effect="solid"
          arrowColor="var(--dark-700)"
          backgroundColor="var(--dark-700)"
        />
      )}
      {/* <JivoScript /> */}
    </Layout>
  )
}
